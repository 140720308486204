import { ReactNode, useEffect, useState } from 'react';

import { useSeoContext } from '@context/seo';

import AuthPopup from '@components/NewAuth';
import { RegistrationPopup } from '@components/RegistratioinPopup';
import LoadWrapper from '@components/controls/LoadWrapper';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { scale } from '@scripts/gds';
import { useGetUserData } from '@scripts/hooks/useGetUserData';

import { SeoHead } from './SeoHead';
import { showPopup } from './util';

type PageWrapperProps = {
    h1?: string;
    title?: string;
    error?: string;
    isLoading?: boolean;
    children: ReactNode;
    className?: string;
    seoProps?: Record<string, string>;
};

const PageWrapper = ({ isLoading = false, error, children, className, seoProps }: PageWrapperProps) => {
    const seoContexData = useSeoContext();
    const [isRegistrationPopup, setIsRegistragionPopup] = useState(false);
    const [isAuthPopup, setIsAuthPopup] = useState(false);
    const seo = {
        ...seoContexData,
        ...seoProps,
    };

    const { user, userData, isLoading: isLoadingUserData } = useGetUserData();

    useEffect(() => {
        if (!user && !userData) {
            showPopup({ setIsRegistragionPopup });
        }
    }, []);

    useEffect(() => {
        if (userData) {
            window.localStorage.removeItem('popupLoad');
            window.localStorage.removeItem('popupLoadDay');
            setIsRegistragionPopup(false);
        }
    }, [userData]);

    return (
        <>
            <SeoHead {...seo} />
            {isRegistrationPopup  && !isLoadingUserData && (
                <RegistrationPopup
                    isOpen={isRegistrationPopup}
                    onClose={() => {
                        setIsRegistragionPopup(false);
                        window.localStorage.setItem('popupLoadDay', new Date().getTime().toString());
                    }}
                    onChangeToRegistration={() => {
                        setIsRegistragionPopup(false);
                        setIsAuthPopup(true);
                        if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                            window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'popup_button_register');
                        }
                    }}
                />
            )}
            <LoadWrapper isLoading={isLoading} error={error}>
                <main
                    css={{
                        padding: 0,
                        paddingBottom: scale(11),
                        height: '100%',
                        minHeight: '50vh',
                    }}
                    className={className}
                >
                    {children}
                </main>
            </LoadWrapper>
            <AuthPopup isRegistration={true} isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} />
        </>
    );
};

export default PageWrapper;
