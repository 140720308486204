import Head from 'next/head';

import { SeoData } from '@api/seo/types';

export const SeoHead = ({ title, canonical, description, image }: SeoData) => {
    return (
        <Head>
            {title && <title>{title}</title>}
            {canonical && <link rel="canonical" href={canonical} />}
            {description && <meta name="description" content={description} />}
            {image && <meta name="og:image" content={image} />}
        </Head>
    );
};
